<template>
  <div class="view view1">
    <Content :view="$store.state.config.views[1]" />
  </div>
</template>

<script>

import Content from '@/components/Content.vue'

export default {
  name: 'View1',
  components: {
    Content
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>
